import { v4 as uuidv4 } from "uuid";
import type { CategoriesOfIngredients, ComponentCategory, PerfumeIngredient } from "@/models";

export interface EditComponentViewModel {
  componentId: string;
  category: ComponentCategory;
  ingredient: PerfumeIngredient | null;
  displayName: string;
  volume: string;
}

export interface EditPerfumeViewModel {
  name: string;
  email: string;
  username: string;
  userLastName: string;
  components: EditComponentViewModel[];
  customIngredients: PerfumeIngredient[];
  ingredientDialogOpen: {
    componentId: string;
  } | null;
}

type UpdatePerfumeViewModelFunc = (vm: EditPerfumeViewModel) => EditPerfumeViewModel;

export interface PerfumeEditor {
  updatePerfumeViewModel: (update: UpdatePerfumeViewModelFunc) => void;
  availableIngredients: CategoriesOfIngredients;
  savePerfume: () => Promise<void>;
  perfumeViewModel: EditPerfumeViewModel;
  totalVolume: number;
}

function updatePerfumeComponent(
  editor: PerfumeEditor,
  componentId: string,
  edit: (component: EditComponentViewModel) => EditComponentViewModel,
) {
  editor.updatePerfumeViewModel((vm) => {
    const components = vm.components.map((component) =>
      component.componentId === componentId ? edit(component) : component,
    );

    return {
      ...vm,
      components,
    };
  });
}

export function addComponentWithDialog(editor: PerfumeEditor, category: ComponentCategory) {
  const newComponent: EditComponentViewModel = {
    componentId: uuidv4(),
    category: category,
    ingredient: null,
    displayName: "",
    volume: "",
  };
  editor.updatePerfumeViewModel((vm) => ({
    ...vm,
    components: [...vm.components, newComponent],
  }));
  openComponentIngredientDialog(editor, newComponent.componentId);
}

export function openComponentIngredientDialog(editor: PerfumeEditor, componentId: string) {
  editor.updatePerfumeViewModel((vm) => ({
    ...vm,
    ingredientDialogOpen: {
      componentId: componentId,
    },
  }));
}

export function closeComponentIngredientDialog(editor: PerfumeEditor) {
  editor.updatePerfumeViewModel((vm) => ({
    ...vm,
    ingredientDialogOpen: null,
  }));
}

export function deleteComponent(editor: PerfumeEditor, componentIdToRemove: string) {
  editor.updatePerfumeViewModel((vm) => ({
    ...vm,
    components: vm.components.filter((c) => c.componentId !== componentIdToRemove),
  }));
}

export function editComponent(
  editor: PerfumeEditor,
  componentId: string,
  {
    displayName,
    ingredient,
    volume,
  }: {
    displayName?: string;
    ingredient?: PerfumeIngredient | null;
    volume?: string;
  },
) {
  updatePerfumeComponent(editor, componentId, (component) => {
    const newComponent = { ...component };

    if (displayName !== undefined) {
      newComponent.displayName = displayName;
    }

    if (ingredient !== undefined) {
      newComponent.ingredient = ingredient;
    }

    if (volume !== undefined) {
      newComponent.volume = adaptVolumeViewModel(volume);
    }

    return newComponent;
  });
}

function adaptVolumeViewModel(volume: string): string {
  const volumeInt = parseInt(volume) || null;
  if (volumeInt === null) {
    return volume;
  }

  const bounded = Math.min(100, Math.max(0, volumeInt));
  return bounded.toLocaleString();
}

export function setPerfumeProperty(
  editor: PerfumeEditor,
  changes: {
    newName?: string;
    newEmail?: string;
    newUsername?: string;
    newUserLastName?: string;
  },
) {
  editor.updatePerfumeViewModel((vm) => ({
    ...vm,
    name: changes.newName ?? vm.name,
    email: changes.newEmail ?? vm.email,
    username: changes.newUsername ?? vm.username,
    userLastName: changes.newUserLastName ?? vm.userLastName,
  }));
}
