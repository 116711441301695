import { useCallback, useMemo, useState } from "react";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import ListOfPerfumesSearchHits, {
  adaptPerfumeSummary,
} from "@/components/ListOfPerfumesSearchHits";
import { InstantSearch, useHits, useSearchBox } from "react-instantsearch";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const searchClient = algoliasearch("ZZYD5NY3M1", "c0852c662ef3b427243ef085c5598daa");

export type PerfumeSearchProps = {
  selectPerfumeId: (perfumeId: string) => void;
};

export default function PerfumeSearch(props: PerfumeSearchProps) {
  return (
    <InstantSearch searchClient={searchClient} indexName="perfume-recipes">
      <CustomSearchBox />
      <CustomHits {...props} />
    </InstantSearch>
  );
}

function CustomSearchBox() {
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);

  const setQuery = useCallback(
    (newQuery: string) => {
      setInputValue(newQuery);
      refine(newQuery);
    },
    [setInputValue, refine],
  );

  return (
    <Paper
      sx={{
        m: "16px 0px 0px 0px",
        p: "4px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <TextField
        sx={{ ml: 1, flex: 1 }}
        label="Rechercher un parfum"
        value={inputValue}
        onChange={(e) => setQuery(e.target.value)}
        variant="standard"
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

function CustomHits({ selectPerfumeId }: PerfumeSearchProps) {
  const { items } = useHits();

  const perfumeHits = useMemo(() => items.map(adaptPerfumeSummary), [items]);

  return <ListOfPerfumesSearchHits perfumes={perfumeHits} selectPerfumeId={selectPerfumeId} />;
}

// type HitProps = {
//   hit: AlgoliaHit<{
//     name: string;
//     email: string;
//     username: string;
//     formulaNumber: string;
//     userLastName: string;
//   }>;
// };

// function HitResult({ hit }: HitProps) {
//   return (
//     <>
//       <Card></Card>
//       <Highlight hit={hit} attribute="name" className="Hit-label" />
//       <Highlight hit={hit} attribute="email" className="Hit-label" />
//       <Highlight hit={hit} attribute="username" className="Hit-label" />
//       <Highlight hit={hit} attribute="formulaNumber" className="Hit-label" />
//       <span className="Hit-price">{hit.formulaNumber}</span>
//     </>
//   );
// }
