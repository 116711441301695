import { type LocalizedText } from "./Language";
import {
  type PerfumeIngredient,
  ComponentCategory,
  defaultPerfumeIngredientIcon,
  type CategoriesOfIngredients,
  sortIngredients,
} from "./Perfume";

export interface GalimardIngredient {
  name: string;
  displayName?: LocalizedText;
  galimardReference: string;
  description?: LocalizedText;
  advice?: string;
  icon?: string;
}

export const AvailableGalimardNoteDeTete: GalimardIngredient[] = [
  {
    name: "ANANAS",
    galimardReference: "901",
    description: {
      fr: 'Ananas est un fruit tropical sucré et juteux, également connu sous le nom de "roi des fruits". Il se distingue par son arôme doux et parfumé qui est à la fois rafraîchissant et exotique.',
      en: 'Ananas is a sweet and juicy tropical fruit, also known as the "king of fruits." It is distinguished by its sweet and fragrant aroma, which is both refreshing and exotic.',
    },
    advice: `Elle évoque des sentiments de positivité et de bonne humeur.`,
    icon: "ananas",
  },
  {
    name: "BERGAMOTE",
    galimardReference: "676",
    description: {
      fr: `L'arôme frais et citronné de la bergamote est souvent utilisé pour ouvrir une composition.`,
      en: "",
    },
    advice: `Elle crée une impression de pureté et de vivacité`,
    icon: "bergamote",
  },
  {
    name: "BERGAMOTE VERTE",
    galimardReference: "602",
    description: {
      fr: `La bergamote verte est une fragrance fraîche et vivifiante, qui évoque l'odeur des feuilles et des fleurs d'agrumes.`,
      en: "",
    },
    advice: `Elle est souvent associée à d'autres notes florales, comme le la rose.`,
    icon: "bergamote-verte",
  },
  {
    name: "BOUTON DE ROSE",
    displayName: {
      fr: "Bouton de Rose",
      en: "",
    },
    galimardReference: "677",
    description: {
      fr: `L'odeur de la fragrance bouton de rose est fraîche et florale, avec une touche de douceur.`,
      en: "",
    },
    advice: `Elle peut être portée au quotidien pour une touche de sophistication.`,
    icon: `bouton-de-rose`,
  },
  {
    name: "CARDAMOME",
    galimardReference: "970",
    description: {
      fr: `La cardamome est une fragrance qui éveille les sens. Elle est chaude et épicée, avec une note balsamique.`,
      en: "",
    },
    advice: `Elle peut être associée à des notes florales, fruitées ou boisées.`,
    icon: "cardamome",
  },
  {
    name: "CITRON AMER",
    displayName: {
      fr: "Citron Amer",
      en: "",
    },
    galimardReference: "678",
    description: {
      fr: `Le citron amer est une fragrance fraîche et vivifiante, qui évoque les beaux jours.`,
      en: "",
    },
    advice: `La fragrance citron amer est idéale pour les personnes qui recherchent une fragrance fraîche et énergisante.`,
    icon: "citron-amer",
  },
  {
    name: "CITRON DOUX",
    galimardReference: "679",
    description: {
      fr: `Le citron doux est une fragrance fraîche et vivifiante qui évoque les fruits juteux et les journées ensoleillées.`,
      en: "",
    },
    advice: `Le citron doux est une fragrance polyvalente qui peut être portée en toute occasion.`,
    icon: "citron-doux",
  },
  {
    name: "CITRON ZESTE",
    galimardReference: "680",
    description: {
      fr: `Le zeste de citron est une note de tête incontournable de la parfumerie. Elle est associée à la fraîcheur, à la tonicité et à la luminosité.`,
      en: "",
    },
    advice: `Le zeste de citron est une note versatile, unisexes qui se caractérise par son acidité.`,
    icon: "citron-zeste",
  },
  {
    name: "COMPLEXE VERT",
    galimardReference: "605",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "complexe-vert",
  },
  {
    name: "CONCOMBRE",
    galimardReference: "954",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "concombre",
  },
  {
    name: "FEUILLES VERTES",
    galimardReference: "606",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "feuilles-vertes",
  },
  {
    name: "FLEUR ORANGER",
    displayName: {
      fr: "Fleur d'Oranger Murcia",
      en: "",
    },
    galimardReference: "347",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fleur-oranger",
  },
  {
    name: "FLORALE FRAICHE",
    displayName: {
      fr: "Florale Fraîche",
      en: "",
    },
    galimardReference: "607",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "florale-fraiche",
  },
  {
    name: "FREESIA",
    galimardReference: "463",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "freesia",
  },
  {
    name: "FRUIT DE BERGAMOTE",
    displayName: {
      fr: "Fruit de Bergamote",
      en: "",
    },
    galimardReference: "608",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fruit-bergamote",
  },
  {
    name: "FRUITS DE CASSIS",
    displayName: {
      fr: "Fruit de Cassis",
      en: "",
    },
    galimardReference: "505",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fruits-decassis",
  },
  {
    name: "FRUITS VERTS",
    galimardReference: "609",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "fruits-verts",
  },
  {
    name: "GALBANUM VERT",
    galimardReference: "683",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "galbanum-vert",
  },
  {
    name: "GERANIUM SAUVAGE",
    displayName: {
      fr: "Géranium Sauvage",
      en: "",
    },
    galimardReference: "610",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "geranium-sauvage",
  },
  {
    name: "HERBE FRAICHE",
    displayName: {
      fr: "Herbe Fraîche",
      en: "",
    },
    galimardReference: "612",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "herbe-fraiche",
  },
  {
    name: "JASMIN ORIENTAL",
    galimardReference: "614",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "jasmin-oriental",
  },
  {
    name: "LAVANDE DES ALPES",
    displayName: {
      fr: "Lavande des Alpes",
      en: "",
    },
    galimardReference: "697",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "lavande-des-alpes",
  },
  {
    name: "LAVANDE SAUVAGE",
    galimardReference: "615",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "lavande-sauvage",
  },
  {
    name: "LIMETTE VERTE",
    galimardReference: "686",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "limette-verte",
  },
  {
    name: "LITCHEE",
    galimardReference: "925",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "litchee",
  },
  {
    name: "LOTUS",
    galimardReference: "921",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "lotus",
  },
  {
    name: "MANDARINE",
    galimardReference: "687",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "mandarine",
  },
  {
    name: "MANDARINE DOUCE",
    galimardReference: "166",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "mandarine-douce",
  },
  {
    name: "MANGUE",
    galimardReference: "908",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "mangue",
  },
  {
    name: "NOTE HESPERIDEE",
    displayName: {
      fr: "Note Hespéridée",
      en: "",
    },
    galimardReference: "688",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "note-hesperidee",
  },
  {
    name: "NOTE VERTE",
    galimardReference: "617",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "note-verte",
  },
  {
    name: "ORANGER",
    galimardReference: "619",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "oranger",
  },
  {
    name: "PAMPLEMOUSSE",
    galimardReference: "122",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "pamplemousse",
  },
  {
    name: "PECHE BLANCHE",
    displayName: {
      fr: "Pêche blanche",
      en: "",
    },
    galimardReference: "912",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "peche-blanche",
  },
  {
    name: "POIRE",
    displayName: {
      fr: "Poire sorbet",
      en: "",
    },
    galimardReference: "956",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "poire",
  },
  {
    name: "POIVRE ROSE",
    galimardReference: "578",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "poivre-rose",
  },
  {
    name: "ROSE DE MAI",
    displayName: {
      fr: "Rose de mai",
      en: "",
    },
    galimardReference: "622",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "bouquet of Rosa Centifolia, detailed, white background"
    icon: "rose-de-mai",
  },
  {
    name: "ROSE FRUITEE",
    displayName: {
      fr: "Rose Fruitée",
      en: "",
    },
    galimardReference: "623",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "rose-fruitee",
  },
  {
    name: "SAUGE SCLAREE",
    displayName: {
      fr: "Sauge Sclarée",
      en: "",
    },
    galimardReference: "722",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "sauge-sclaree",
  },
  {
    name: "SECRET DU PARFUMEUR",
    displayName: {
      fr: "Secret du parfumeur",
      en: "",
    },
    galimardReference: "749",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "secret scent fragrance, detailed, white background"
    icon: "secret-du-parfumeur",
  },
  {
    name: "THE ANGLAIS",
    displayName: {
      fr: "Thé Anglais",
      en: "",
    },
    galimardReference: "931",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "the-anglais",
  },
  {
    name: "THE VERT",
    displayName: {
      fr: "Thé Vert",
      en: "",
    },
    galimardReference: "932",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "green tea, glass cup, detailed, white background"
    icon: "the-vert",
  },
  {
    name: "TOMATE",
    galimardReference: "916",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "sliced tomato on a small wooden plank, detailed, white background"
    icon: "tomate",
  },
  {
    name: "VERVEINE",
    galimardReference: "689",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "verbena verveine, detailed, white background"
    icon: "verveine",
  },
  {
    name: "YUZU",
    galimardReference: "968",
    description: { fr: ``, en: "" },
    advice: ``,
    icon: "yuzu",
  },
];

export const AvailableGalimardNoteDeCoeur: GalimardIngredient[] = [
  {
    name: "BOIS DOUX",
    galimardReference: "692",
    icon: "bois-doux",
  },
  {
    name: "AIR DE PROVENCE",
    displayName: {
      fr: "Air de Provence",
      en: "",
    },
    galimardReference: "448",
    icon: "air-de-provence",
  },
  {
    name: "ALDEHYDAL",
    galimardReference: "295",
    icon: "aldehydal",
  },
  {
    name: "CHEVREFEUILLE",
    displayName: {
      fr: "Chèvrefeuille",
      en: "",
    },
    galimardReference: "445",
    icon: "chevrefeuille",
  },
  {
    name: "COCKTAIL",
    galimardReference: "591",
    icon: "cocktail",
  },
  {
    name: "ACCORD JASMIN",
    displayName: {
      fr: "Accord Jasmin",
      en: "",
    },
    galimardReference: "690",
    icon: "accord-jasmin",
  },
  {
    name: "BAMBOU",
    galimardReference: "922",
    icon: "bambou",
  },
  {
    name: "COMPLEXE FRUITE",
    displayName: {
      fr: "Complexe Fruité",
      en: "",
    },
    galimardReference: "627",
    icon: "complexe-fruite",
  },
  {
    name: "FIGUE",
    galimardReference: "903",
    icon: "figue",
  },
  {
    name: "FLEUR DE GRENADIER",
    displayName: {
      fr: "Fleur de Grenadier",
      en: "",
    },
    galimardReference: "905",
    icon: "fleur-de-grenadier",
  },
  {
    name: "FLEUR DE JACINTHE",
    galimardReference: "630",
    icon: "fleur-de-jacinthe",
  },
  {
    name: "FLEUR DE LILAS",
    galimardReference: "629",
    icon: "fleur-de-lilas",
  },
  {
    name: "FLEURI YLANG",
    galimardReference: "628",
    icon: "fleuri-ylang",
  },
  {
    name: "GARDENIA",
    galimardReference: "904",
    icon: "gardenia",
  },
  {
    name: "GERANIUM",
    displayName: {
      fr: "Géranium",
      en: "",
    },
    galimardReference: "694",
    icon: "geranium",
  },
  {
    name: "GINGEMBRE",
    galimardReference: "955",
    icon: "gingembre",
  },
  {
    name: "JASMIN MUSQUE",
    displayName: {
      fr: "Jasmin Musqué",
      en: "",
    },
    galimardReference: "632",
    icon: "jasmin-musque",
  },
  {
    name: "JASMIN ORANGE",
    galimardReference: "633",
    icon: "jasmin-orange",
  },
  {
    name: "JASMIN SAUVAGE",
    galimardReference: "696",
    icon: "jasmin-sauvage",
  },
  {
    name: "MAGNOLIA",
    galimardReference: "907",
    icon: "magnolia",
  },
  {
    name: "MUGUET",
    galimardReference: "638",
    icon: "muguet",
  },
  {
    name: "MUGUET DE MAI",
    displayName: {
      fr: "Muguet de Mai",
      en: "",
    },
    galimardReference: "639",
    icon: "muguet-de-mai",
  },
  {
    name: "MUGUET DES BOIS",
    displayName: {
      fr: "Muguet des bois",
      en: "",
    },
    galimardReference: "640",
    icon: "muguet-des-bois",
  },
  {
    name: "MUGUET MUSQUE",
    displayName: {
      fr: "Muguet Musqué",
      en: "",
    },
    galimardReference: "698",
    icon: "muguet-musque",
  },
  {
    name: "NEROLI",
    galimardReference: "699",
    icon: "neroli",
  },
  {
    name: "NOTE CANNELLE",
    galimardReference: "444",
    // prompt: "cinnamon stick and cinnamon powder on a small wooden plank, detailed, white background"
    icon: "note-cannelle",
  },
  {
    name: "NOTE MARINE",
    galimardReference: "488",
    // prompt: "calm sea in a scent bottle, detailed, watercolor, white background"
    icon: "note-marine",
  },
  {
    name: "NOTE OCEANE",
    displayName: {
      fr: "Note Océane",
      en: "",
    },
    galimardReference: "927",
    // prompt: "scent of note marine, ocean wave in a bottle, detailed, watercolor, white background"
    icon: "note-oceane",
  },
  {
    name: "NOTE TABAC",
    galimardReference: "643",
    // prompt: "small bag of tobacco on dried tobacco leave, detailed, white background"
    icon: "note-tabac",
  },
  {
    name: "NOTE CIVETTE",
    galimardReference: "642",
    // prompt: "painting of the face of a civet, stylished, watercolor, white background"
    icon: "note-civette",
  },
  {
    name: "PIN BOISE",
    displayName: {
      fr: "Pin Boisé",
      en: "",
    },
    galimardReference: "703",
    // prompt: "painting of a Pinus pinaster, stylished, watercolor, white background"
    icon: "pin-boise",
  },
  {
    name: "PIVOINE",
    galimardReference: "923",
    // prompt: "single red Peony with a few leaves, stylished, watercolor, white background"
    icon: "pivoine",
  },
  {
    name: "PRUNE",
    galimardReference: "914",
    description: { fr: ``, en: "" },
    advice: ``,
    // prompt: "sliced Plum on a small wooden plank, detailed, white background"
    icon: "prune",
  },
  {
    name: "REGLISSE",
    displayName: {
      fr: "Réglisse",
      en: "",
    },
    galimardReference: "565",
    // prompt: "liquorice on a small wooden plank, detailed, white background"
    icon: "reglisse",
  },
  {
    name: "ROSE D'ORIENT",
    displayName: {
      fr: "Rose d'Orient",
      en: "",
    },
    galimardReference: "646",
    // prompt: "Rose bush, watercolor detailed, white background"
    icon: "rose-orient",
  },
  {
    name: "ROSE PETALES",
    displayName: {
      fr: "Rose pétale",
      en: "",
    },
    galimardReference: "647",
    // prompt: "bowl of rose petals, watercolor detailed, white background"
    icon: "rose-petales",
  },
  {
    name: "ROSE SAUVAGE",
    galimardReference: "648",
    // prompt: "closeup on wild rose in a forest, watercolor detailed, white background"
    icon: "rose-sauvage",
  },
  {
    name: "TIARE",
    displayName: {
      fr: "Tiaré",
      en: "",
    },
    galimardReference: "919",
    // prompt: photoshop generative fill with seeds from google image
    icon: "tiare",
  },
  {
    name: "TILLEUL",
    galimardReference: "915",
    // prompt: "painting of a linden branch with leaves, watercolor, white background"
    icon: "tilleul",
  },
  {
    name: "VIOLETTE DE PARME",
    displayName: {
      fr: "Violette de Parme",
      en: "",
    },
    galimardReference: "935",
    icon: "violette-de-parme",
  },
];

export const AvailableGalimardNoteDeFond: GalimardIngredient[] = [
  {
    name: "ACCORD MUSC",
    displayName: {
      fr: "Accord Musc",
      en: "",
    },
    galimardReference: "705",
    icon: "accord-musc",
  },
  {
    name: "AMANDE",
    galimardReference: "900",
    icon: "amande",
  },
  {
    name: "AMBRE",
    galimardReference: "650",
    // prompt: "small amber rocks on a small wooden plank, detailed, white background"
    icon: "ambre",
  },
  {
    name: "AMBRE ORIENTAL",
    galimardReference: "247",
    // prompt: "a few amber rocks with burning incense sticks on a small wooden plank, detailed, white background"
    icon: "ambre-oriental",
  },
  {
    name: "AMBRE VERT",
    galimardReference: "652",
    // prompt: "a few small amber rocks and wood moss on a small wooden plank, detailed, white background"
    icon: "ambre-vert",
  },
  {
    name: "AMBREINE",
    displayName: {
      fr: "Ambréine",
      en: "",
    },
    galimardReference: "653",
    icon: "ambreine",
  },
  {
    name: "BOIS AMBRE",
    displayName: {
      fr: "Bois Ambré",
      en: "",
    },
    galimardReference: "654",
    icon: "bois-ambre",
  },
  {
    name: "BOIS DE SANTAL",
    displayName: {
      fr: "Bois de Santal",
      en: "",
    },
    galimardReference: "655",
    icon: "bois-de-santal",
  },
  {
    name: "COMPLEXE BOISE",
    displayName: {
      fr: "Complexe Boisé",
      en: "",
    },
    galimardReference: "661",
    icon: "complexe-boise",
  },
  {
    name: "BOIS ET TONKA",
    displayName: {
      fr: "bois et Tonka",
      en: "",
    },
    galimardReference: "723",
    icon: "bois-et-tonka",
  },
  {
    name: "BOIS MOUSSE",
    galimardReference: "656",
    icon: "bois-mousse",
  },
  {
    name: "BOISE CEDRE",
    displayName: {
      fr: "Boisé cèdre",
      en: "",
    },
    galimardReference: "659",
    icon: "boise-cedre",
  },
  {
    name: "BOUQUET BOISE",
    displayName: {
      fr: "Bouquet Boisé",
      en: "",
    },
    galimardReference: "706",
    // prompt: "bouquet boisé lying down, top view, detailed, white background"
    icon: "bouquet-boise",
  },
  {
    name: "CHOCOLAT",
    displayName: {
      fr: "Chocolat",
      en: "",
    },
    galimardReference: "",
    icon: "chocolat",
  },
  // {
  //   name: "CONCRETAL",
  //   displayName: {
  //     fr: "Contrétal",
  //     en: "",
  //   },
  //   galimardReference: "477",
  //   // icon: "concretal",
  // },
  {
    name: "CUIR",
    galimardReference: "458",
    // prompt: "leather gloves on top on each other on a small table with a ancien perfume spray, detailed, white background"
    icon: "cuir",
  },
  {
    name: "ECORCE",
    displayName: {
      fr: "Écorce",
      en: "",
    },
    galimardReference: "707",
    icon: "ecorce",
  },
  {
    name: "FEVE TONKA",
    displayName: {
      fr: "Fève Tonka",
      en: "",
    },
    galimardReference: "662",
    // prompt: "longues fève noires ridée on a small wooden plank, detailed, white background"
    icon: "feve-tonka",
  },
  {
    name: "FLEUR DE MUGUET",
    displayName: {
      fr: "Fleur de muguet",
      en: "",
    },
    galimardReference: "708",
    // prompt: "bouquet of lily of the valley in bloom lying down, top view, detailed, white background"
    icon: "fleur-de-muguet",
  },
  {
    name: "FRUITS SECS",
    galimardReference: "710",
    // prompt: "dried fruit  on a small wooden plank, detailed, white background"
    icon: "fruits-secs",
  },
  {
    name: "IRIS",
    galimardReference: "663",
    icon: "iris",
  },
  {
    name: "LICHEN",
    galimardReference: "664",
    // prompt: "lichen on a granite rock, detailed, white background"
    icon: "lichen",
  },
  {
    name: "LILAS",
    galimardReference: "665",
    // prompt: "bouquet of lila lying down, top view, detailed, white background"
    icon: "lilas",
  },
  {
    name: "MOUSSE",
    galimardReference: "711",
    icon: "mousse",
  },
  {
    name: "MOUSSE D'ARBRE",
    displayName: {
      fr: "Mousse d'arbre",
      en: "",
    },
    galimardReference: "712",
    // prompt: "tree moss on a trunk detailed, white background"
    icon: "mousse-arbre",
  },
  {
    name: "MOUSSE DE BAVIERE",
    displayName: {
      fr: "Mousse de Bavière",
      en: "",
    },
    galimardReference: "478",
    // tree moss in Bavaria on a trunk detailed, white background
    icon: "mousse-de-baviere",
  },
  {
    name: "MOUSSE VERTE",
    galimardReference: "713",
    // prompt: "close-up of a rock covered with green moss"
    icon: "mousse-verte",
  },
  {
    name: "MUSC",
    // prompt: "happy musk deer, ears pointing up, detailed, white background"
    galimardReference: "666",
    icon: "musc",
  },
  {
    name: "MUSC BLANC",
    galimardReference: "715",
    // prompt: "musk deer walking in the forest, a sheet is drying in the background, clean, detailed, white background"
    icon: "musc-blanc",
  },
  {
    name: "MUSC DOUX",
    // prompt: "happy musk deer without wood, detailed, white background"
    galimardReference: "667",
    icon: "musc-doux",
  },
  {
    name: "MUSC FLORAL",
    // prompt: "musk deer, walking on flowers, detailed, white background"
    galimardReference: "668",
    icon: "musc-floral",
  },
  {
    name: "NOTE PRALINE",
    galimardReference: "504",
    // prompt: "bowl of brown pralines shaped as almonds in cooked sugar placed on a small wooden plank, detailed, white background"
    icon: "note-praline",
  },
  {
    name: "NOTE CASTOREUM",
    galimardReference: "669",
    // icon: "note castoreum",
  },
  {
    name: "VETIVER",
    galimardReference: "675",
    displayName: {
      fr: "Vétiver",
      en: "",
    },
    // prompt: "Vetivergras, detailed, white background"
    icon: "vetiver",
  },
  {
    name: "SOUS BOIS",
    galimardReference: "719",
    icon: "sous-bois",
  },
  {
    name: "RACINES DE VETIVER",
    displayName: {
      fr: "Racines de Vétiver",
      en: "",
    },
    galimardReference: "716",
    // prompt: "vetiver with it's roots, detailed, white background"
    icon: "racines-de-vetiver",
  },
  {
    name: "PATCHOULI",
    galimardReference: "390",
    // prompt: "patchouli, detailed, white background"
    icon: "patchouli",
  },
  {
    name: "OPOPONAX",
    galimardReference: "806",
    icon: "opoponax",
  },
  {
    name: "SANTAL",
    galimardReference: "671",
    icon: "santal",
  },
  {
    name: "SANTAL EXOTIQUE",
    galimardReference: "717",
    icon: "santal-exotique",
  },
  {
    name: "SANTAL ORIENT",
    displayName: {
      fr: "Santal d'Orient",
      en: "",
    },
    galimardReference: "672",
    icon: "santal-orient",
  },
  {
    name: "SANTALINE",
    galimardReference: "718",
    icon: "santaline",
  },
  {
    name: "TUBEREUSE",
    displayName: {
      fr: "Tubéreuse",
      en: "",
    },
    galimardReference: "720",
    icon: "tubereuse",
  },
  {
    name: "VANILLE",
    galimardReference: "198",
    icon: "vanille",
  },
];

function adaptPerfumeIngredient(
  galimard: GalimardIngredient,
  category: ComponentCategory,
): PerfumeIngredient {
  if (galimard.displayName !== undefined) {
    galimard.displayName.fr = galimard.displayName.fr.replace("'", "’");
  }
  return {
    name: galimard.name,
    displayName: galimard.displayName ?? null,
    description: galimard.description ?? null,
    advice: galimard.advice ?? "",
    galimardReference: galimard.galimardReference,
    category: category,
    icon: galimard.icon ? `/ingredients/${galimard.icon}` : defaultPerfumeIngredientIcon,
  };
}

function adaptCategoryOfIngredients(
  category: ComponentCategory,
  galimardIngredients: GalimardIngredient[],
) {
  return sortIngredients(
    galimardIngredients.map((ingredient) => adaptPerfumeIngredient(ingredient, category)),
  );
}

export const AvailablePerfumeIngredients: CategoriesOfIngredients = {
  [ComponentCategory.NOTE_DE_TETE]: adaptCategoryOfIngredients(
    ComponentCategory.NOTE_DE_TETE,
    AvailableGalimardNoteDeTete,
  ),
  [ComponentCategory.NOTE_DE_COEUR]: adaptCategoryOfIngredients(
    ComponentCategory.NOTE_DE_COEUR,
    AvailableGalimardNoteDeCoeur,
  ),
  [ComponentCategory.NOTE_DE_FOND]: adaptCategoryOfIngredients(
    ComponentCategory.NOTE_DE_FOND,
    AvailableGalimardNoteDeFond,
  ),
} as const;

export const allAvailableIngredients: PerfumeIngredient[] = Object.values(
  AvailablePerfumeIngredients,
).flat();
