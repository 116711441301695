import type {
  Firestore,
  CollectionReference,
  DocumentReference,
  Query,
} from "firebase/firestore/lite";
import {
  setDoc,
  getDoc,
  getDocs,
  doc,
  collection,
  query,
  deleteDoc,
  orderBy,
  where,
  limit,
  runTransaction,
} from "firebase/firestore/lite";
import { makeFormulaNumber, type Perfume } from "@/models";
import { PerfumeConverter, keyOf, type PerfumeDb } from "./PerfumeDb";
import {
  PerfumeNumberCounterConverter,
  type PerfumeNumberCounterDb,
} from "./PerfumeNumberCounterDb";

enum Collections {
  Perfumes = "perfume-recipes",
  FormulaNumber = "formula-number",
}

export class PerfumeRepositoryFirebase {
  firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  _listPerfumesQuery(limitNumberOfPerfume: number): Query<Perfume, PerfumeDb> {
    return query(
      this.perfumesCollection,
      orderBy(keyOf<PerfumeDb>("created"), "desc"),
      limit(limitNumberOfPerfume),
    );
  }

  async fetchPerfumes(query: Query<Perfume, PerfumeDb>): Promise<Perfume[]> {
    const perfumes = await getDocs(query);
    return perfumes.docs.map((d) => d.data());
  }

  async listPerfumeOfOrgan({ organId, limit }: { organId: string; limit: number }) {
    return this.fetchPerfumes(
      query(
        this._listPerfumesQuery(limit),
        where(keyOf<PerfumeDb>("perfumeOrganId"), "==", organId),
      ),
    );
  }

  async listPerfume(limitNumberOfPerfume: number = 1e6) {
    return this.fetchPerfumes(this._listPerfumesQuery(limitNumberOfPerfume));
  }

  get perfumesCollection(): CollectionReference<Perfume, PerfumeDb> {
    return collection(this.firestore, Collections.Perfumes).withConverter(PerfumeConverter);
  }

  fireStorePerfumeDocument(recipeId: string): DocumentReference<Perfume, PerfumeDb> {
    return doc(this.perfumesCollection, recipeId);
  }

  get incrementFormulaNumberDocument(): DocumentReference<number, PerfumeNumberCounterDb> {
    return doc(collection(this.firestore, Collections.FormulaNumber), "increment").withConverter(
      PerfumeNumberCounterConverter,
    );
  }

  async createPerfume(perfume: Perfume, formulaNumberPrefix: string): Promise<Perfume> {
    return runTransaction(this.firestore, async (transaction) => {
      // get current formula counter
      const counterDocument = await transaction.get(this.incrementFormulaNumberDocument);
      const counter = counterDocument.data() ?? 111;

      // increment formula counter
      transaction.set(this.incrementFormulaNumberDocument, counter + 1);

      // create new formula number
      const perfumeWithFormulaNumber: Perfume = {
        ...perfume,
        formulaNumber: makeFormulaNumber({
          formulaNumberPrefix: formulaNumberPrefix,
          formulaNumber: counter,
        }),
      };

      transaction.set(this.fireStorePerfumeDocument(perfume.recipeId), perfumeWithFormulaNumber);

      return perfumeWithFormulaNumber;
    });
  }

  async savePerfume(perfume: Perfume) {
    const perfumeDocument = this.fireStorePerfumeDocument(perfume.recipeId);
    await setDoc(perfumeDocument, perfume);
  }

  async deletePerfume(recipeId: string) {
    const perfumeDocument = this.fireStorePerfumeDocument(recipeId);
    await deleteDoc(perfumeDocument);
  }

  async perfumeById(recipeId: string): Promise<Perfume | null> {
    const dto = await getDoc(this.fireStorePerfumeDocument(recipeId));

    return dto.data() ?? null;
  }

  async perfumeByFormulaNumber(formulaNumber: string): Promise<Perfume | null> {
    const q = query(
      this.perfumesCollection,
      where(keyOf<PerfumeDb>("formulaNumber"), "==", formulaNumber),
    );

    const result = await getDocs(q);
    if (result.empty) {
      return null;
    }
    if (result.size > 1) {
      throw Error(`Multiple perfumes with the same formulaNumber: ${formulaNumber}`);
    }
    return result.docs[0].data();
  }
}
