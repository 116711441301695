import { useCallback, useState } from "react";
import type { CSSProperties, FC, SyntheticEvent } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import FrenchFlag from "country-flag-icons/react/3x2/FR";
import GreatBritainFlag from "country-flag-icons/react/3x2/GB";
import { isLanguage, Language } from "@/models";
import { dataAttribute } from "./domUtils";
import { useTranslation } from "react-i18next";
import { defaultLanguage } from "@/services";

export default function LanguageMenu() {
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const closeMenu = useCallback(() => setAnchorElLang(null), []);

  const { i18n } = useTranslation();
  const activeLanguage = i18n.resolvedLanguage;
  const ingredientLanguage = isLanguage(activeLanguage) ? activeLanguage : defaultLanguage;

  const handleLanguageClick = useCallback(
    (event: SyntheticEvent) => {
      const clickedLanguage = dataAttribute(event, "lang");
      i18n.changeLanguage(clickedLanguage);
      closeMenu();
    },
    [i18n, closeMenu],
  );

  const flagsViewModel: Record<Language, FC<{ title: string; style: CSSProperties }>> = {
    fr: FrenchFlag,
    en: GreatBritainFlag,
  };

  const CurrentLanguageFlag = flagsViewModel[ingredientLanguage];

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenLangMenu}
        color="inherit"
      >
        <CurrentLanguageFlag title={ingredientLanguage} style={{ width: "30px" }} />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElLang}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElLang)}
        onClose={closeMenu}
      >
        {Object.entries(flagsViewModel).map(([availableLanguage, FlagComponent]) => (
          <MenuItem
            selected={ingredientLanguage === availableLanguage}
            data-lang={availableLanguage}
            key={availableLanguage}
            onClick={handleLanguageClick}
          >
            <FlagComponent title={availableLanguage} style={{ width: "30px" }} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
