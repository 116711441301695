import type { LocalizedText } from "./Language";
import type { WorkshopLocation, PerfumeOrgan } from "./WorkshopLocationAndOrgan";

export interface Perfume {
  recipeId: string;
  formulaNumber: string | null;
  created: Date;
  name: string;
  email: string;
  username: string;
  userLastName: string;
  components: PerfumeComponent[];
  workshopLocation: WorkshopLocation | null;
  perfumeOrgan: PerfumeOrgan | null;
}

export type PerfumeSummary = Pick<
  Perfume,
  "recipeId" | "formulaNumber" | "name" | "username" | "email" | "userLastName" | "created"
>;

export enum ComponentCategory {
  NOTE_DE_TETE,
  NOTE_DE_COEUR,
  NOTE_DE_FOND,
}

export interface PerfumeIngredient {
  name: string;
  displayName: LocalizedText | null;
  description: LocalizedText | null;
  advice: string;
  galimardReference: string;
  category: ComponentCategory;
  icon: string;
}

export type CategoriesOfIngredients = {
  [category in ComponentCategory]: readonly PerfumeIngredient[];
};

export interface PerfumeComponent {
  name: string;
  volume: number;
  category: ComponentCategory;
}

export function removeEmptyComponents(components: PerfumeComponent[]): PerfumeComponent[] {
  return components.filter((c) => !!c.name);
}

export function perfumeCountComponents(perfume: Perfume): number {
  return removeEmptyComponents(perfume.components).length;
}

export function perfumeVolume(perfume: Perfume): number {
  return removeEmptyComponents(perfume.components).reduce((acc, component) => {
    return acc + component.volume;
  }, 0);
}

export function makeFormulaNumber({
  formulaNumberPrefix,
  formulaNumber,
}: {
  formulaNumberPrefix: string;
  formulaNumber: number;
}): string {
  return `${formulaNumberPrefix}-${String(formulaNumber).padStart(5, "0")}`;
}

export const defaultPerfumeIngredientIcon = "/ingredients/unknown-ingredient";

export function newPerfumeIngredient({
  name,
  category,
}: {
  name: string;
  category: ComponentCategory;
}): PerfumeIngredient {
  return {
    name,
    displayName: null,
    description: null,
    advice: "",
    galimardReference: "",
    category,
    icon: defaultPerfumeIngredientIcon,
  };
}

export function sortIngredients(ingredients: PerfumeIngredient[]): PerfumeIngredient[] {
  return [...ingredients].sort((a, b) => a.name.localeCompare(b.name));
}

export function buildIngredients({
  ingredients,
  additionalIngredients,
}: {
  ingredients: CategoriesOfIngredients;
  additionalIngredients: PerfumeIngredient[];
}): CategoriesOfIngredients {
  const result: CategoriesOfIngredients = { ...ingredients };

  // add custom ingredients
  additionalIngredients.forEach((ingredientToAdd) => {
    const category = ingredientToAdd.category;
    // sort of each added ingredient (the number of ingredient to add is expected to be low)
    result[category] = sortIngredients([...result[category], ingredientToAdd]);
  });

  return result;
}

export function makeIngredient(
  component: PerfumeComponent,
  available: PerfumeIngredient[],
  option?: {
    createMissingIngredient: boolean;
  },
): PerfumeIngredient | null {
  if (!component.name) {
    return null;
  }
  const componentName = component.name.toUpperCase();
  const ingredient =
    available.find((ingredient) => ingredient.name.toUpperCase() === componentName) ?? null;

  if (ingredient === null && option?.createMissingIngredient) {
    return newPerfumeIngredient({
      name: component.name,
      category: component.category,
    });
  }
  return ingredient;
}
