import { PerfumeTunnelPageStepId } from "./PerfumeTunnelPageStepId";

export enum PathFragment {
  Account = "account",
  Perfume = "perfume",
  PerfumeDetails = "perfume-details",
  Perfumes = "perfumes",
  Workshop = "workshop",
  Organ = "organ",
  Admin = "admin",
  IngredientInventory = "ingredient-inventory",
}

export const PageLink = {
  Home: "/",
  Account: `/${PathFragment.Account}`,
  Perfume: `/${PathFragment.Perfume}`,
  Perfumes: `/${PathFragment.Admin}/${PathFragment.Perfumes}`,
  loginWithRedirect: (redirect?: string) => {
    const queryParams = redirect ? `?${new URLSearchParams({ redirect })}` : "";
    return `${PageLink.Account}${queryParams}`;
  },
  perfumeDetails: (recipeId: string) => `/${PathFragment.Perfumes}/${encodeURIComponent(recipeId)}`,
  perfume: ({
    formulaNumber,
    step,
  }: {
    formulaNumber: string | null;
    step?: PerfumeTunnelPageStepId;
  }) => {
    if (formulaNumber === null) {
      throw Error("Can't navigate to perfume without formulaNumber");
    }
    const stepString = PerfumeTunnelPageStepId[step ?? PerfumeTunnelPageStepId.Parfum];
    return `/${PathFragment.Perfume}/${encodeURIComponent(formulaNumber)}/${encodeURIComponent(
      stepString,
    )}`;
  },

  workshopLocationOrgan: ({
    workshopLocationId,
    organId,
  }: {
    workshopLocationId: string;
    organId: string;
  }) => {
    const workshopFragment = `${PathFragment.Workshop}/${encodeURIComponent(workshopLocationId)}`;
    const organFragment = `${PathFragment.Organ}/${encodeURIComponent(organId)}`;
    return `/${workshopFragment}/${organFragment}`;
  },
} as const;

export function isRouteRequireAuthenticatedUser(path: string) {
  return path.startsWith(PathFragment.Admin);
}
