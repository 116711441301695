import { useCallback, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import PerfumeComposer from "@/components/PerfumeComposer";
import { LoadingDispatcher } from "@/hooks/Loading";
import { services } from "@/injectServices";
import { PageLink, PerfumeTunnelPageStepId, type Perfume } from "@/models";
import { usePerfumeEditor } from "@/services";
import { PerfumeDetails } from "./PerfumeDetails";
import { PerfumeEditor, setPerfumeProperty } from "@/services/perfume-editor";
import { useNavigate } from "react-router-dom";
import { debounce } from "@mui/material/utils";
import { useTranslation } from "react-i18next";

interface PageStep {
  step: PerfumeTunnelPageStepId;
  title: string;
  nextButton: string | null;
}

export function PerfumeTunnel({
  loadingDispatcher,
  backendPerfume,
  setBackendPerfume,
  activePageNumber,
}: {
  loadingDispatcher: LoadingDispatcher;
  backendPerfume: Perfume;
  setBackendPerfume: (perfume: Perfume) => void;
  activePageNumber: PerfumeTunnelPageStepId;
}) {
  const { t } = useTranslation();
  const editor = usePerfumeEditor({
    service: services().perfumeService,
    loadingDispatcher,
    backendPerfume,
    setBackendPerfume,
  });

  const displayedPages: ReadonlyArray<PageStep> = useMemo(
    () =>
      [
        {
          step: PerfumeTunnelPageStepId.Identification,
          title: t("identificationStep"),
          nextButton: t("navNext"),
        },
        {
          step: PerfumeTunnelPageStepId.Parfum,
          title: t("perfumeStep"),
          nextButton: t("navLastStep"),
        },
        {
          step: PerfumeTunnelPageStepId.Diploma,
          title: t("diplomaStep"),
          nextButton: null,
        },
      ] as const,
    [t],
  );

  const previousPage = displayedPages[activePageNumber - 1];
  const activePage = displayedPages[activePageNumber];
  const nextPage = displayedPages[activePageNumber + 1];

  const navigate = useNavigate();

  const navigateToStep = useCallback(
    (toStep: PerfumeTunnelPageStepId) => {
      navigate(
        PageLink.perfume({
          formulaNumber: backendPerfume.formulaNumber,
          step: toStep,
        }),
      );
    },
    [backendPerfume.formulaNumber, navigate],
  );

  const savePerfume = useMemo(() => {
    return debounce((e: PerfumeEditor) => {
      e.savePerfume();
    }, 750);
  }, []);

  // save when page change
  useEffect(() => savePerfume(editor), [editor, savePerfume]);

  const theme = useTheme();
  const stepperOrientation = useMediaQuery(theme.breakpoints.down("sm"))
    ? "vertical"
    : "horizontal";

  return (
    <>
      <Stepper activeStep={activePageNumber} sx={{ pt: 3, pb: 5 }} orientation={stepperOrientation}>
        {displayedPages.map((page) => (
          <Step key={page.step}>
            <StepLabel>{page.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        <Box
          sx={{
            display:
              activePage.step !== PerfumeTunnelPageStepId.Identification ? "none" : undefined,
          }}
        >
          {t("identificationStep")}
          <FormControl
            fullWidth
            sx={{
              my: 2,
            }}
          >
            <TextField
              required
              label="Email"
              value={editor.perfumeViewModel.email}
              onChange={(e) =>
                setPerfumeProperty(editor, {
                  newEmail: e.target.value,
                })
              }
              variant="standard"
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 2,
            }}
          >
            <TextField
              required
              label={t("firstName")}
              value={editor.perfumeViewModel.username}
              onChange={(e) =>
                setPerfumeProperty(editor, {
                  newUsername: e.target.value,
                })
              }
              variant="standard"
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 2,
            }}
          >
            <TextField
              required
              label={t("lastName")}
              value={editor.perfumeViewModel.userLastName}
              onChange={(e) =>
                setPerfumeProperty(editor, {
                  newUserLastName: e.target.value,
                })
              }
              variant="standard"
            />
          </FormControl>
        </Box>

        <PerfumeComposer
          editor={editor}
          hide={activePage.step !== PerfumeTunnelPageStepId.Parfum}
        />
      </>

      {activePage.step === PerfumeTunnelPageStepId.Diploma && (
        <PerfumeDetails perfume={backendPerfume} />
      )}

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {previousPage && (
          <Button onClick={() => navigateToStep(previousPage.step)} sx={{ mt: 3, ml: 1 }}>
            {t("navBack")}
          </Button>
        )}

        {nextPage && (
          <Button
            variant="contained"
            onClick={() => navigateToStep(nextPage.step)}
            sx={{ mt: 3, ml: 1 }}
          >
            {activePage.nextButton}
          </Button>
        )}
      </Box>
    </>
  );
}
