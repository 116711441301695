import App from "./App";
import { PerfumeTunnelPage } from "@/pages/PerfumeTunnelPage";
import { AccountPage } from "@/pages/AccountPage";
import { PerfumeListPage } from "./pages/PerfumeListPage";
import { PerfumeDetailsPage } from "./pages/PerfumeDetailsPage";
import { ErrorPage } from "@/pages/ErrorPage";
import { HomePage } from "./pages/HomePage";
import { IngredientInventoryPage } from "./pages/IngredientInventoryPage";
import { PathFragment } from "@/models";

import { createRoutesFromElements, Route } from "react-router-dom";

export function appRoutes() {
  return createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index element={<HomePage />} />
      <Route path={PathFragment.Perfume} element={<PerfumeTunnelPage />} />
      <Route
        path={`${PathFragment.Workshop}/:workshopLocationId/${PathFragment.Organ}/:organId`}
        element={<PerfumeTunnelPage />}
      />
      <Route
        path={`${PathFragment.Perfume}/:formulaNumber/:step?`}
        element={<PerfumeTunnelPage />}
      />
      <Route path={PathFragment.Account} element={<AccountPage />} />
      <Route
        path={`${PathFragment.Admin}/${PathFragment.Perfumes}`}
        element={<PerfumeListPage />}
      />
      <Route
        path={`${PathFragment.Admin}/${PathFragment.IngredientInventory}`}
        element={<IngredientInventoryPage />}
      />
      <Route path={`${PathFragment.Perfumes}/:perfumeId`} element={<PerfumeDetailsPage />} />
    </Route>,
  );
}
