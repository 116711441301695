import { type FirestoreDataConverter, type QueryDocumentSnapshot } from "firebase/firestore/lite";

export interface PerfumeNumberCounterDb {
  counter: number;
}

export const PerfumeNumberCounterConverter: FirestoreDataConverter<number, PerfumeNumberCounterDb> =
  {
    toFirestore: (counter): PerfumeNumberCounterDb => {
      return {
        counter: counter,
      };
    },
    fromFirestore: function (snapshot: QueryDocumentSnapshot<PerfumeNumberCounterDb>): number {
      return snapshot.data().counter;
    },
  };
